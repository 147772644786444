<div class="box-with-title">
  <div nz-row [nzGutter]="12">
    <div nz-col nzSpan="18" class="pd-0">
      <div class="outreach-item">
        <div>
          <div *ngIf="sentBidSummary?.total_carrier" class="flex left15 top10 medium f15">
            <div class="left5">{{ sentBidSummary.total_carrier }} {{ sentBidSummary.total_email > 1 ? 'Carriers' : 'Carrier' }},</div>
            <div class="left5">{{ sentBidSummary.total_sms }} SMS,</div>
            <div class="left5">{{ sentBidSummary.total_email }} {{ sentBidSummary.total_email > 1 ? 'emails' : 'email' }} sent,</div>
            <div *ngIf="sentBidSummary.cost_sms" class="left5">Cost: ~{{ sentBidSummary.cost_sms }}</div>
          </div>
          <div class="ware-list" #waveListContainer>
            <button *ngIf="isShowBtnPrev" class="btn-prev" nz-button nzType="default" (click)="onBtnPrev()">
              <span nz-icon nzType="left" nzTheme="outline"></span>
            </button>
            <ng-container *ngIf="isLoadWave">
              <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
            </ng-container>
            <ng-container *ngIf="!isLoadWave">
              <ng-container *ngIf="!waveListResponsive.length">
                <div class="nodata"><i nz-icon nzTheme="outline" nzType="search"></i>No data</div>
              </ng-container>
              <ng-container *ngIf="waveListResponsive.length">
                <ng-container *ngFor="let ware of waveListResponsive; let i = index" >
                  <div detail-bid-ware-item
                    [index]="i+1"
                    [time]="ware.time"
                    [isActive]="ware.isActive"
                    [isLine]="!!((i + waveListCurrentPage * waveListCount)<(wares.length - 1))"
                    [carrierCount]="ware.carrierCount"
                    [sendEmailCount]="ware.sendEmailCount"
                    [sendSmsCount]="ware.sendSmsCount"
                    [isActiveBeforeItem]="!!(waveListResponsive?.[i - 1]?.isActive)"
                    [waveListCurrentPage]="waveListCurrentPage"
                    [waveListCount]="waveListCount"
                    [createdBy]="ware.createdBy"
                  ></div>
                </ng-container>
                <div *ngIf="waveListCurrentPage < totalPage - 1 && totalPage > 1" class="ware-line"></div>
              </ng-container>
            </ng-container>
            <button *ngIf="isShowBtnNext" class="btn-next" nz-button nzType="default" (click)="onBtnNext()">
              <span nz-icon nzType="right" nzTheme="outline"></span>
            </button>
          </div>
        </div>
        <div *ngIf="!isLoadWave" class="bottom10" style="margin-left: 25px;">
          <div *ngIf="numOfCarrierMatched" class="medium f15 top10 bottom10">
            {{numOfCarrierMatched}} {{ numOfCarrierMatched > 1 ? 'Carriers' : 'Carrier' }} Matched
          </div>
          <div *ngIf="historyAddCarrier.length">
            <div *ngFor="let item of historyAddCarrier; let i = index" style="margin-bottom: 3px;">
              <ng-container *ngIf="i<2">
                <span> {{ item?.summary }}</span>
                <span *ngIf="item?.datetime" style="margin-left: 10px; color: #A1A1AA">{{ item.datetime }}</span>
              </ng-container>
            </div>
            <a *ngIf="historyAddCarrier.length > 2" class="f13" 
              nz-popover 
              [nzPopoverContent]="tooltipAllHistoryInfo"
              nzPopoverTitle="All history"
              nzPopoverTrigger="click">
              Show all history
            </a>
            <ng-template #tooltipAllHistoryInfo>
              <div style="padding: 8px 16px;">
                <div *ngFor="let item of historyAddCarrier" style="margin-bottom: 5px;">
                  <span> {{ item?.summary }}</span>
                  <span *ngIf="item?.datetime" style="margin-left: 10px; color: #A1A1AA">{{ item.datetime }}</span>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div class="outreach-item-right"  nz-col nzSpan="6">
      <div class="outreach-item">
        <ng-container *ngIf="isbidPlaceCountLoading">
          <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
        </ng-container>
        <ng-container *ngIf="!isLoadWave">
          <div style="padding: 20px;">
            <div class="outreach-text-right">
              <div class="number">{{bidPlaceCount}}</div>
              <div>{{bidPlaceCount > 1 ? 'Bids' : 'Bid'}} placed</div>
            </div>
            <div class="flex">
              <!-- <button class="btn-stop" nz-button nzType="default" nzDanger>Stop</button> -->
              <button (click)="onViewAnalytics()" class="btn-view-analytics"  nz-button nzType="default"><span nz-icon nzType="bar-chart" nzTheme="outline"></span> View analytics</button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>