import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
@Component({
  selector: '[detail-bid-ware-item]',
  templateUrl: 'index.html',
  styleUrls: [
    './index.scss',
  ]
})
export class DetailBidWareItem extends BaseComponent {
  @Input() index: number = 0;
  @Input() time: string;
  @Input() isActive: boolean = false;
  @Input() isActiveBeforeItem: boolean = false;
  @Input() isLine: boolean = true;
  @Input() carrierCount: number = 0;
  @Input() sendEmailCount: number = 0;
  @Input() sendSmsCount: number = 0;
  @Input() waveListCurrentPage: number = 0;
  @Input() waveListCount: number = 0;
  @Input() createdBy: string = '';
}
