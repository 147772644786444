import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Utils } from "@services/utils";
import _ from 'underscore';
import { Const } from "@const/Const";
import { InputHelper } from "@services/input-helper";
import { DateUtil } from "@services/date-utils";
import dayjs from "dayjs";
import { DialogService } from "@dialogs/dialog.service";
import { CommLogList } from "@app/admin/components/comm-log";
import { Const as WarpConst } from "@wearewarp/universal-libs";
import { EditCounterComponent } from "@app/admin/carrier-bids/components/edit-counter/edit-counter.component";
import { CounterForCarrierComponent } from "@app/admin/carrier-bids/components/counter-for-carrier/counter-for-carrier.component";
import { EditCarrierBidAnswerComponent } from "@app/admin/carrier-bids/components/edit-carrier-bid-answer/edit-carrier-bid-answer.component";
import { AssignCarrier } from "@app/admin/carrier-bids/components/assign-carrier";
import { CarrierSaleDetailService } from "@app/admin/carrier-sale-detail/carrier-sale-detail.service";
import { BaseComponent } from "@abstract/BaseComponent";
import { BidCandidate, Contact } from "@wearewarp/types/data-model";
import { AddCarrier } from "../add-carrier";
import { AddCarrierPool } from "../add-carrier-pool";
import { AddCarrierByServiceArea } from "../add-carrier-by-area";
import { AddCarrierPoolByArea } from "../add-carrier-pool-by-area";
import { EditCarrierLookups } from "@app/admin/carrier-bids/components/detail-carrier-bid/edit-carrier-lookups-dialog";
import { NoteList } from "../notes";
import { SendASAPDlg } from "../send-asap";

enum TabFilter {
  responseReceived = 'response-received',
  noRessponse = 'no-response'
}

@Component({
  selector: "carrier-sale-carrier-result",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class CarrierSaleCarrierResult extends BaseComponent {
  constructor(private carrierSaleDetailService: CarrierSaleDetailService) {
    super();
  }

  @Input() bid: any;
  @Output() onReload: EventEmitter<any> = new EventEmitter<any>();

  public isLoading: boolean = false;
  public candidates: (BidCandidate & {
    noteCount: number,
    goHighWayLink?: string,
    goHightWayClassifications?: string,
    goHightWayStatus?: string
    countOfJob: number,
    countOfJobRateLike: number,
    countOfJobRateDislike: number,
    lastCompleteJob?: any,
    countLane: number,
    countJobUnassigned: number,
    lastCost?: any,
    contacts: Array<Contact & {
      contactEmail?: string,
      contactPhone?: string,
    }>,
    lastSentSms?: any,
    lastSentEmail?: any,
  })[] = [];
  public totalCount: number;
  public responsedCount: number = 0;
  public noResponseCount: number = 0;
  private notSentCount: number = 0;
  private sentButNoResponseCount: number = 0;
  public limit: number = Const.PAGINATION_LIMIT;
  public pageIndex: number = 1;
  private _selectedTabIndex = 0;
  public isCountLoading: boolean = false;
  carrierIds: string[] = [];
  contactInfos: any[] = [];
  bidCandidateIds: string[] = [];
  isOverPickupTime: boolean = false;
  ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(this.carrierSaleDetailService.getCarrierTabFilter().subscribe(tabFilter => {
      if (!tabFilter) {
        this.onSetCarrierTabFilter();
      } else {
        this.pageIndex = tabFilter.pageIndex;
        this._selectedTabIndex = tabFilter?.selectedTabIndex;
        this.limit = tabFilter?.limit;
        this.searchKeyword = tabFilter.searchKeyword;
        this.getListCarriers();
        this.getCountCarriersForTabs();
      }
    }));
    this.subscription.add(
      this.carrierSaleDetailService.noteCountSubject.subscribe((data) => {
        this.candidates = this.candidates.map((item) => {
          item.noteCount = data[item.carrierId] || 0;
          return item;
        });
      })
    );
    this.subscription.add(
      this.carrierSaleDetailService.isOverPickupTime$.subscribe(data => {
        this.isOverPickupTime = data;
      })
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getListCarriers() {
    this.isLoading = true;
    this.api.GET(this.getApiByTab(this.currentTabFilter)).subscribe(
      res => {
        this.isLoading = false;
        this.carrierSaleDetailService.setCarriers(res.data?.list_data || []);
        this.candidates = this.onGetDataSucceeded(res.data?.list_data || []);
        this.totalCount = res.data?.total;
        this.carrierIds = [];
        this.contactInfos = [];
        this.bidCandidateIds = [];
        for (const item of this.candidates) {
          if (item.id && !this.bidCandidateIds.includes(item.id)) {
            this.bidCandidateIds.push(item.id);
          }
          if (!this.carrierIds.includes(item.carrierId)) {
            this.carrierIds.push(item.carrierId);
          }
          const contactPhone = item.contacts?.[0]?.contactPhone;
          const contactEmail = item.contacts?.[0]?.contactEmail;
          const countryCode = "+1";
          if (contactPhone) {
            const owner = contactPhone.replace(/[^0-9]/g, "");
            const conditions = {
              origin: contactPhone,
              owner: countryCode + owner,
              type: 'phone',
            };
            this.contactInfos.push(conditions);
          }
          if (contactEmail) {
            const owner = contactEmail.toLowerCase();
            const conditions = {
              origin: contactEmail,
              owner,
              type: 'email',
            };
            this.contactInfos.push(conditions);
          }
        }
        const candidateRefused = this.candidates.filter(item => item.state === Const.CarrierBidState.Refused);
        if (candidateRefused.length) {
          const candidateNotRefused = this.candidates.filter(item => item.state !== Const.CarrierBidState.Refused);
          this.candidates = [...candidateNotRefused, ...candidateRefused];
        }
        
        this.goHighWayInfoForByCarrieIds();
        this.performanceForCarrierList();
        this.checkUnsubscribe();
        this.commlogHistoriesByCandidate();
      },
      error => {
        this.showErr(error);
        this.isLoading = false;
      }
    )
  }

  goHighWayInfoForByCarrieIds() {
    if (!this.carrierIds?.length) return;
    const url = `${Const.APIV2(Const.APIURI_CARRIER_BIDS)}/go-highWay-info-for-by-carries`;
    return this.api.POST(url, { carrierIds: this.carrierIds }).subscribe(
      (resp) => {
        const data = resp?.data?.list_data ?? [];
        this.candidates = this.candidates.map((candidate) => {
          const item = data.find(it => it.carrierId == candidate.carrierId);
          if (item) {
            candidate.goHighWayLink = item.goHighWayLink;
            candidate.goHightWayStatus = item.goHightWayStatus;
            candidate.goHightWayClassifications = item.goHightWayClassifications;
          }
          return candidate;
        });

      },
      (err) => {

      }
    );
  }

  getCountCarriersForTabs() {
    this.api.GET(this.getCarrierCountApiByTab()).subscribe(
      res => {
        this.responsedCount = res.data?.responsedCount;
        this.noResponseCount = res.data?.noResponseCount;
        this.notSentCount = res.data?.notSentCount;
        this.sentButNoResponseCount = res.data?.sentButNoResponseCount;
      },
      error => {
        this.showErr(error);
      }
    )
  }



  onGetDataSucceeded(data) {
    const pools = this.bid?.pools || [];
    const result = data.map(item => {
      let pool = pools.find(it => it.id === item.poolId);
      let data = {};
      if (pool) data = { isDedicatedPool: pool.isDedicatedPool, basePrice: pool?.dedicatedInfo?.baseRate ?? item?.basePrice, poolName: pool?.name }
      const bidAnswers = this.getCounterBidHistory(item);
      const info = this.getSourceObj(item);
      const equipment = (item.fleets || []).filter(it => it?.vehicleType?.name).map(it => it?.vehicleType?.name).join(', ') || item?.equipment;
      const documentWarning = this.getDocumentWarning(item.documents);
      return {
        ...item,
        ...data,
        equipment,
        bidAnswers,
        source: info,
        documents: documentWarning.length ? { isPassed: false, messages: documentWarning } : { isPassed: true },
        rawSource: item.source,
        name: item?.name ?? item.metadata?.carrier?.name
      }
    })
    return result;
  }

  private getDocumentWarning(documents) {
    if (!Utils.isObjectNotEmpty(documents)) return [];
    const typeRequired = ['w9Document', 'autoInsurance', 'generalInsurance', 'cargoInsurance']
    const insuranceRequired = ['autoInsurance', 'generalInsurance', 'cargoInsurance', 'authorityDocument']
    let arr = [];
    for (let key of typeRequired) {
      if (!Utils.isArrayNotEmpty(documents[key]?.uploadIds)) {
        arr.push({ status: 'Missing', label: this.getDocumentLabelByKey(key) });
      }
    }
    for (let key of insuranceRequired) {
      if (key == 'authorityDocument') {
        if ((documents[key]?.uploadIds?.length) && (!documents[key]?.insurance?.startDate || !documents[key]?.insurance?.endDate)) {
          arr.push({ status: 'Missing', label: `${this.getDocumentLabelByKey(key)} Date` });
        } else if (this.checkExpired(documents[key])) {
          arr.push({ status: 'Expired', label: `${this.getDocumentLabelByKey(key)} is expired` });
        }
      } else {
        if (!documents[key]?.insurance?.startDate || !documents[key]?.insurance?.endDate) {
          arr.push({ status: 'Missing', label: `${this.getDocumentLabelByKey(key)} Date` });
        } else if (this.checkExpired(documents[key])) {
          arr.push({ status: 'Expired', label: `${this.getDocumentLabelByKey(key)} is expired` });
        }
      }
    }
    return arr;
  }

  private getDocumentLabelByKey(key) {
    switch (key) {
      case 'w9Document': return 'W-9 Document';
      case 'autoInsurance': return 'Auto Insurance';
      case 'generalInsurance': return 'General Insurance';
      case 'cargoInsurance': return 'Cargo Insurance';
      case 'authorityDocument': return 'Authority Document Insurance';
    }
  }

  private checkExpired(item) {
    if (!item?.insurance?.endDate) {
      return false;
    }
    const now = new Date().toISOString();
    const endDate = new Date(item?.insurance?.endDate).toISOString();
    return endDate < now ? true : false;
  }

  onTabSelectedIndexChange(value: number) {
    this._selectedTabIndex = value;
    this.pageIndex = 1;
    this.onSetCarrierTabFilter();
  }

  private onSetCarrierTabFilter() {
    this.carrierSaleDetailService.setCarrierTabFilter({
      pageIndex: this.pageIndex,
      searchKeyword: this.searchKeyword,
      selectedTabIndex: this.selectedTabIndex,
      limit: this.limit,
    });
  }

  onChangePage(value: number) {
    this.pageIndex = value;
    this.onSetCarrierTabFilter();
  }

  get selectedTabIndex() {
    return this._selectedTabIndex;
  }

  get currentTabFilter(): TabFilter {
    switch (this._selectedTabIndex) {
      case 0: return TabFilter.responseReceived;
      case 1: return TabFilter.noRessponse;
      default: return TabFilter.responseReceived;
    }
  }

  public getTitleTab(tab: string): string {
    const tabs = {
      'response-received': `Response Received (${this.responsedCount})`,
      'no-response': `No response (${this?.noResponseCount})`
    }
    return tabs[tab];
  }

  get isActiveResponseReceivedTab(): boolean {
    return this.currentTabFilter === 'response-received';
  }

  get isActiveNoResponseTab(): boolean {
    return this.currentTabFilter === 'no-response';
  }

  get hasDedicatedPool(): boolean {
    return !!this.bid.pools?.length
  }

  private getApiByTab(tab: TabFilter) {
    const skip = this.pageIndex ? (this.pageIndex - 1) * this.limit : 0;
    return `${Const.APIV2(Const.APIURI_CARRIER_BIDS)}/${this.bid.id}/get_list_carriers?filter=${encodeURIComponent(JSON.stringify({ type: tab, search: this.searchKeyword }))}&skip=${skip}&limit=${this.limit}`;
  }

  private getCarrierCountApiByTab() {
    return `${Const.APIV2(Const.APIURI_CARRIER_BIDS)}/${this.bid.id}/get-carrier-count-for-carrier-bid-detail?filter=${JSON.stringify({ search: this.searchKeyword })}`;
  }

  public searchKeyword: string = '';
  doSearch(value: string) {
    this.searchKeyword = value;
    this.pageIndex = 1;
    this.onSetCarrierTabFilter();
  }

  $asCarrierStatusText = (status) => {
    const statusKey = Object.keys(Const.CarrierStatus).find((key) => Const.CarrierStatus[key] == status) || "";
    const text = this.getStatusCarrier(status);
    return text || this.capitalizeFirstLetter(statusKey);
  };

  $asMoney = (money) => {
    return InputHelper.formatMoney1(money + '');
  };
  $formatDate = (date) => {
    return DateUtil.dateToString(date, Const.FORMAT_GUI_DATETIME_SHORT);
  }
  $displayEstTime = (date) => {
    return dayjs(date).tz('America/New_York').format('MMM DD, hh:mm A');
  }
  $formatBidPublicDate = (isoStr) => {
    return DateUtil.displayLocalTime(isoStr, { format: Const.FORMAT_GUI_DATETIME_SHORT });
  }

  get localTimzone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  $asSentStatusColor = (status = "") => {
    switch (status.toLowerCase()) {
      case 'pending':
        return 'gray';
      case 'sent':
      case 'success':
      case 'unknown':
        return 'green';
      case 'failed':
      case 'invalid':
      case 'unsubscribed':
        return 'red';
      case 'delivered':
      case 'opened':
      case 'clicked':
        return 'blue';
      case 'undelivered':
      case 'unreachable':
        return 'orange';
      default:
        return '';
    }
  }

  $shouldShowHistory = (item) => {
    return item?.logId
  }

  $goHighWayStatus = (status: string) => {
    switch (status) {
      case 'partial_pass':
        return 'Partial Pass';
      case 'pass':
        return 'Pass';
      case 'incomplete':
        return 'Incomplete';
      case 'fail':
        return 'Fail';
      default:
        return 'N/A';
    }
  }

  $goHighWayColor = (status: string) => {
    switch (status) {
      case 'pass':
      case 'partial_pass':
        return 'success';
      case 'fail':
        return 'red';
      default:
        return '';
    }
  }

  $asSentStatusText = (status = "") => {
    switch (status.toLowerCase()) {
      case 'pending':
        return 'Pending';
      case 'sent':
      case 'unknown':
        return 'Sent';
      case 'failed':
        return 'Failed';
      case 'success':
        return 'Success';
      case 'delivered':
        return 'Delivered';
      case 'opened':
        return 'Opened';
      case 'clicked':
        return 'Clicked';
      case 'unsubscribed':
        return 'Unsubscribed';
      case 'undelivered':
        return 'Undelivered';
      case 'invalid':
        return 'Invalid';
      case 'unreachable':
        return 'Unreachable';
      default:
        return status;
    }
  };

  $documentColor = (isPassed: boolean) => {
    return isPassed ? 'success' : 'default';
  }

  private getSourceObj = (item) => {
    let source = item?.metadata?.source;
    if (item.poolId) source = 'CARRIER_POOL';
    if (!source) return '-';
    let desc = '';
    let category = '';
    switch (source) {
      case 'GEO_FEATURE_ASSIGNED':
      case 'GEO_FEATURE_PLACED':
      case 'SAME_MARKET':
      case 'COVERAGE_AREA':
      case 'PREFERRED_LANE':
        category = 'WARP';
        desc = `Auto-added`;
        break;
      case 'EXTERNAL_MATCHING':
        category = this.capitalizeFirstLetter(source);
        desc = item?.metadata?.searching?.source ?? '';
        break;
      case 'CARRIER_POOL':
        category = 'WARP';
        desc = `CARRIER POOL`;
        break;
      case 'MANUAL':
        category = 'WARP';
        desc = item?.metadata?.addedBy?.name ? `${item?.metadata?.addedBy?.name} added` : '';
        break;
      case 'LOAD_BOARD_PUBLIC':
      case 'loadBoardPublic':
        category = 'Public Load Board';
        break;
      case 'loadBoard':
        category = 'Load Board';
        break;
      default:
        category = source;
    }
    return { category, desc }
  }

  onBtnHistoryStatus(item) {
    const { histories } = item;
    console.log(histories);
    let allHistories = histories.map(it => {
      return {
        ...it,
        ...(it.contact ?? {}),
      }
    })
    allHistories = allHistories.sort(function (a, b) {
      let bDate = new Date(b.when)
      let aDate = new Date(a.when)
      return Number(bDate) - Number(aDate)
    });

    if (!allHistories.length) return;
    DialogService.openFormDialog1(CommLogList, {
      nzComponentParams: {
        model: { histories: _.uniq(allHistories, it => it.logId) },
        closeOnSuccess: true
      },
      nzClassName: "comm-log-form modal-no-padding modal-xl",
    });
  }

  public hasCounterBidHistory(item) {
    if (item?.bidAnswers && Utils.isArrayNotEmpty(item?.bidAnswers)) return true;
    return false;
  }

  public getAuthorOfBidCounter(couterItem) {
    if (couterItem?.entity == 'carrier') return 'Carrier';
    if (couterItem?.entity == 'admin') return 'Warp';
    return 'N/A'
  }

  public getCounterBidHistory(item) {
    let answers = item?.bidCounterOffer?.answers || [];
    if (item?.price && !item?.bidCounterOffer?.answers && !item?.bidCounterOffer?.answers.length) {
      answers.unshift({
        price: item.price,
        entity: WarpConst.BidCounterOfferEntities.carrier,
        update: item?.update
      })
    }
    return answers;
  }

  public checkCanCounter(item) {
    if (this.bid?.job?.assignedCarrier?.carrierId) return false;
    if (item?.bidCounterOffer?.status == WarpConst.BidCounterStatus.waitAdminReply) return true;
    if (item?.status === 'un_registered') return false;
    if (item?.price && !item?.bidCounterOffer) return true;
    return false;
  }

  public checkCanHelpCarrierCounter(item) {
    if (this.bid?.job?.assignedCarrier?.carrierId) return false;
    if (item?.bidCounterOffer?.status && item?.bidCounterOffer?.status == WarpConst.BidCounterStatus.waitCarrierReply) return true;
    return false;
  }

  public isCarrierAcceptedPrice(item) {
    return (item?.bidCounterOffer?.status == WarpConst.BidCounterStatus.accepted)
  }

  public isCarrierRefusedBid(item) {
    return item?.state == Const.CarrierBidState.Refused;
  }

  public getCarrierAcceptedPrice(item) {
    return item?.price
  }

  public isAdminAssignCarrier() {
    return this.bid?.job?.assignedCarrier?.carrierId;
  }

  public formatDate(date: string) {
    if (!date) return '';
    return DateUtil.formatDate(date, Const.FORMAT_GUI_DATETIME_V3) + ' ';
  }

  public getTooltipForBidCounterAction(counter) {
    return `${this.formatDate(counter?.update?.when)}${counter?.entityName ? 'by ' + counter.entityName : ''}`
  }

  public getLastAnswer(item) {
    let answers = item?.bidAnswers || [];
    if (answers.length) return answers[answers.length - 1];
    return {};
  }

  public onOpenCounterDialog(item) {
    DialogService.openFormDialog1(EditCounterComponent, {
      nzComponentParams: {
        carrierBid: item,
        closeOnSuccess: true,
        updateSuccess: resp => {
          this.onReload.emit();
        }
      },
    })
  }

  public onBtnCounterBidForCarrier(item) {
    DialogService.openFormDialog1(CounterForCarrierComponent, {
      nzComponentParams: {
        carrierBidItem: item,
        carrierBidInfo: this.bid,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          this.onReload.emit();
        },
      },
      nzClassName: "modal",
    });
  }

  isTruckSearch(item) {
    return item.requestId && (item.rawSource == 'truckSearch' || item.source?.category == 'EXTERNAL_MATCHING');
  }

  onBtnEditItem(item) {
    if (item.carrierId) {
      DialogService.openFormDialog1(EditCarrierBidAnswerComponent, {
        nzComponentParams: {
          carrierBidItem: item,
          carrierBidInfo: this.bid,
          closeOnSuccess: true,
          cusSuccessMes: 'Please review in Response Received tab',
          updateSuccess: (resp) => {
            this.onReload.emit();
          },
        },
        nzClassName: "modal",
      });
    } else if (this.isTruckSearch(item)) {
      DialogService.openFormDialog1(EditCarrierLookups, {
        nzComponentParams: {
          type: 'answer',
          carrierBidItem: {
            id: item?.requestId,
            answer: {
              price: item.price,
              state: item.state
            }
          },
          carrierBidInfo: this.bid,
          closeOnSuccess: true,
          cusSuccessMes: 'Please review in Response Received tab',
          updateSuccess: (resp) => {
            this.onReload.emit();
          }
        }
      })
    }

  }

  onBtnEditNote(item) {
    //
    if (item.carrierId) {
      this.drawerService.create({
        nzTitle: `Carrier: ${item.name}`,
        nzContent: NoteList,
        nzWidth: 500,
        nzContentParams: {
          bidId: this.bid.id,
          carrierId: item.carrierId,
          routeCode: this.bid.jobCode,
          carrierName: item.name,
          carrierNote: item.note
        }
      })
    } else if (this.isTruckSearch(item)) {
      DialogService.openFormDialog1(EditCarrierLookups, {
        nzComponentParams: {
          type: 'note',
          carrierBidItem: {
            id: item?.requestId,
            note: item?.note,
            answer: {
              price: item.price,
              state: item.state
            }
          },
          carrierBidInfo: this.bid,
          closeOnSuccess: true,
          updateSuccess: (resp) => {
            this.onReload.emit();
          }
        }
      })
    }

  }

  gotoDispatch() {
    return `${Const.routeAdminDispatchList}/${this.bid.jobId}`
  }

  onBtnAcceptBid(carrier: any) {
    let cost = this.bid.baseRate?.grandTotal === carrier.price ? this.bid.baseRate : this.carrierSaleDetailService.makeCost(carrier)
    DialogService.openFormDialog1(AssignCarrier, {
      nzComponentParams: {
        jobId: this.bid.jobId,
        isRequireCarrierAcceptLoadTender: this.bid?.isRequireCarrierAcceptLoadTender ?? false,
        matchedCarrier: carrier,
        cost: cost,
        job: this.bid?.job,
        closeOnSuccess: true,
        updateSuccess: resp => {
          this.onReload.emit();
          this.showDialog(`Carrier has been assigned successfully.<br /><br />
            <a href="${this.gotoDispatch()}" target="_blank">Go to dispatch</a>
          `);
        }
      },
      nzClassName: 'modal-no-padding assign-carrier-form',
    });
  }

  get isPopulated() {
    return this.bid?.job?.shipments?.length;
  }

  get isMarketplace() {
    return this.bid?.job?.type === WarpConst.JobType.ghost && this.bid?.job?.source == WarpConst.JobSources.marketplace;
  }

  openAddCarrierModal() {
    DialogService.openFormDialog1(AddCarrier, {
      nzComponentParams: {
        model: this.bid,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          this.getListCarriers();
          this.getCountCarriersForTabs();
        },
      },
      nzClassName: "send-mail-form modal-xl",
    });
  }

  openAddCarrierPoolModal() {
    DialogService.openFormDialog1(AddCarrierPool, {
      nzComponentParams: {
        model: this.bid,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          this.getListCarriers();
          this.getCountCarriersForTabs();
        },
      },
      nzClassName: "add-carrier-pool-form modal-xl",
    });
  }

  onAddCarriersByArea() {
    DialogService.openDialog(AddCarrierByServiceArea, {
      nzComponentParams: {
        model: this.bid,
        onSave: this.addCarrierByCoverage.bind(this)
      },
      replaceWrapClassName: true,
      nzClosable: false,
      nzClassName: "add-carrier-pool-by-coverage modal-xxl",
    });
  }

  private addCarrierByCoverage(carrierIds: string[] = []) {
    let carriers = [];
    const currentCarriers = this.bid?.metadata?.carriers || [];
    for (let carrier of currentCarriers) {
      carriers.push({ carrierId: carrier?.carrierId })
    }
    for (let carrierId of carrierIds) {
      let exist = carriers.find(it => it.carrierId === carrierId);
      if (!exist) carriers.push({ carrierId })
    }

    this.api.POST(`${Const.APIURI_CARRIER_BIDS}/${this.bid?.id}/update-carrier`, {
      carriers: carriers
    }).subscribe(
      (response) => {
        // this.onReload.emit();
        this.getListCarriers();
        this.getCountCarriersForTabs();
        const isWarning = carriers.length > 300;
        const ui_message = carriers.length > 300 ? "There are over 300 carriers on this bid. Please ensure all recipients are suitable to avoid excessive emails or SMS" : "Add Carriers successfully";
        if (isWarning) this.showWarning("", ui_message);
        else this.showInfo(ui_message);
      },
      (err) => {
        this.showErr(err);
      }
    );
  }

  onAddPoolsByArea() {
    DialogService.openDialog(AddCarrierPoolByArea, {
      nzComponentParams: {
        model: this.bid,
        onSave: this.addCarrierByCoverage.bind(this),
      },
      nzClassName: "add-carrier-pool-by-coverage modal-xxl",
    });
  }

  isEmailSending: boolean;
  sendInviteRegisterToCarrierPublic(item) {
    if (this.isEmailSending) return;
    this.isEmailSending = true;
    let url = `${Const.APIURI_CARRIER_BIDS}/send-register-invite-bid-public`;
    let data = { logId: item.requestId }
    this.api.POST(url, data).subscribe(
      resp => {
        this.showSuccess('Sent successfully');
        this.isEmailSending = false;
        this.getListCarriers();
        this.getCountCarriersForTabs();
      }, err => {
        this.showErr(err);
        this.isEmailSending = false;
      }
    )
  }

  sendInviteRegisterToCarrier(item) {
    this.isEmailSending = true;
    let url = `${Const.APIURI_CARRIER_LOOKUP}/${this.bid.id}/${item.requestId}/send-register-invite`;
    let params = {}
    this.api.POST(url, params).subscribe(
      (resp) => {
        this.isEmailSending = false;
        this.getListCarriers();
        this.getCountCarriersForTabs();
        this.showSuccess('Sent successfull');
      },
      (err) => {
        this.isEmailSending = false;
        this.showErr(err);
      }
    )
  }

  getLastSendInvite(carrier) {
    const inviteHistories = carrier?.inviteHistories || [];
    const when = inviteHistories[inviteHistories?.length - 1]?.whenBy?.when;
    if (when) {
      return new Date(when).toLocaleString();
    } else {
      return ''
    }
  }

  isCarrierDNU(item) {
    return item.status == Const.CarrierStatus.DNU || item.status == Const.CarrierStatus.deactivate;
  }

  get isDisableBtnAsap(): boolean {
    return !this.notSentCount;
  }

  get isDisableBtnSendRemind(): boolean {
    return !this.sentButNoResponseCount;
  }

  confirmSendAsap() {
    if (this.isOverPickupTime) {
      return this.openWarningOverPickupTime();
    }
    DialogService.openDialog1(SendASAPDlg, {
      nzComponentParams: {
        bidId: this.bid.id,
        type: 'sendASAP',
        onSave: () => this.api.POST(Const.APIV2(`${Const.APIURI_CARRIER_BIDS}/${this.bid.id}/update-queue`)),
        onDone: (data) => {
          this.onReload.emit();
          this.onTabSelectedIndexChange(1);
          this.showSuccess('Requeue successfull');
        }
      },
    });
  }

  confirmSendRemind() {
    if (this.isOverPickupTime) {
      return this.openWarningOverPickupTime();
    }
    DialogService.openDialog1(SendASAPDlg, {
      nzComponentParams: {
        bidId: this.bid.id,
        type: 'sendRemind',
        onSave: () => this.api.POST(Const.APIV2(`${Const.APIURI_CARRIER_BIDS}/${this.bid.id}/send-remind`)),
        onDone: (resp) => {
          this.onReload.emit();
          this.onTabSelectedIndexChange(1);
          this.showSuccess(resp);
        }
      },
    });
  }

  private openWarningOverPickupTime() {
    this.modalService.create({
      nzTitle: 'Update Pickup Time Required',
      nzContent: 'Pickup Time is over, please update the new pickup time then to continue bidding.',
      nzOkText: 'Go to update',
      nzWidth: '600px',
      nzOnOk: () => {
        this.carrierSaleDetailService.setOpenModalUpdatePickupTime(true);
      },
    });
  }

  whyAdded(item) {
    if (!item?.metadata?.source) return;
    let text = '';
    switch (item.metadata.source) {
      case 'GEO_FEATURE_ASSIGNED':
        text += `This carrier was assigned to same lane in the past. LOAD: ${item.metadata.loadCode}.`;
        break;
      case 'GEO_FEATURE_PLACED':
        text += `This carrier placed bid to same lane in the past. LOAD: ${item.metadata.loadCode}.`;
        break;
      case 'EXTERNAL_MATCHING':
        text += `This carrier was matched by external system. Source: ${item.metadata?.searching?.source}`;
        break;
      case 'SAME_MARKET':
        text += `This carrier placed bid to same MARKET in the past. Source: ${item.metadata?.searching?.market}. LOAD: ${item.metadata?.loadCode}`;
        break;
      case 'PREFERRED_LANE':
        text += `This carrier has set this lane in the profile. Lane: ${item.metadata?.searching?.from?.city}, ${item.metadata?.searching?.from?.state} -> ${item.metadata?.searching?.to?.city}, ${item.metadata?.searching?.to?.state}`;
        break;
      default:
        text += item.metadata.source;
    }
    if (item.metadata?.searching?.radius) {
      text += ` Radius: ${item.metadata?.searching?.radius} miles.`
    }
    return text;
  }

  getCandidateLastestHistory(item) {
    const { histories = [] } = item;
    if (!histories?.length) return '';

    const lastestHistory = histories.slice(-1)[0];
    return lastestHistory;
  }

  isCandidateUnassigned(item) {
    const lastestHistory = this.getCandidateLastestHistory(item);
    if (!lastestHistory || lastestHistory.action !== 'unassign') return false;
    return true;
  }

  getCancelMessage(item) {
    return `Cancelled send bid due to ${item.sendBidQueueNote} with same lane in the past.`;
  }

  onBtnChangSendBidQueueStatus(item) {
    this.confirmYesNo(`This carrier may not response because they declined or never response this lane before!<br> Are you sure to add to invitation queue?`, () => {
      this.api.POST(Const.APIV2(`${Const.APIURI_CARRIER_BIDS}/${this.bid.id}/active-send-bid-queue`), { carrierId: item.carrierId }).subscribe(
        (resp) => {
          this.onReload.emit();
          this.showSuccess('Update successfull');
        },
        (err) => {
          this.showErr(err);
        }
      );
    });
  }

  onBtnSendLostBid(item) {
    this.confirmYesNo(`Are you sure to send email lost bid to carrier?`, () => {
      const url = `${Const.APIURI_CARRIER_BIDS}/${this.bid.id}/send-lost-bid`;
      this.api.POST(url, { carrierId: item.carrierId }).subscribe(
        (resp) => {
          this.onReload.emit();
          this.showSuccess('Send email successfull');
        },
        (err) => {
          this.showErr(err);
        }
      );
    });
  }

  performanceForCarrierList() {
    const url = `${Const.APIV2(Const.APIURI_CARRIER_BIDS)}/performance-for-carrier-list`;
    const bid = this.bid;
    const dropoffCode = bid?.dropoffAddress?.zipcode;
    const pickupCode = bid?.pickupAddress?.zipcode
    if (this.carrierIds?.length && dropoffCode && pickupCode) {
      return this.api.POST(url, { carrierIds: this.carrierIds, pickupCode, dropoffCode }).subscribe(
        (resp) => {
          const data = resp?.data?.list_data ?? [];
          this.candidates = this.candidates.map((candidate) => {
            const item = data.find(it => it.carrierId == candidate.carrierId);
            if (item) {
              candidate.countOfJob = item.countOfJob;
              candidate.countOfJobRateLike = item.countOfJobRateLike;
              candidate.countOfJobRateDislike = item.countOfJobRateDislike;
              candidate.lastCompleteJob = item.lastCompleteJob;
              candidate.lastCost = item.lastCost;
              candidate.countLane = item.countLane;
              candidate.countJobUnassigned = item.countJobUnassigned;
            }
            return candidate;
          });
        },
        (err) => {

        }
      );
    }
  }

  commlogHistoriesByCandidate() {
    const url = `${Const.APIV2(Const.APIURI_CARRIER_BIDS)}/commlog-histories-by-candidate`;

    if (this.bidCandidateIds?.length) {
      return this.api.POST(url, { bidCandidateIds: this.bidCandidateIds }).subscribe(
        (resp) => {
          const data = resp?.data?.list_data ?? [];
          this.candidates = this.candidates.map((candidate) => {
            const item = data.find(it => it.id == candidate.id);
            if (item) {
              candidate.lastSentSms = item.lastSentSms;
              candidate.lastSentEmail = item.lastSentEmail;
              candidate.histories = item.histories;
            }
            return candidate;
          });

        },
        (err) => {

        }
      );
    }
  }

  checkUnsubscribe() {
    const contactInfos = this.contactInfos;
    if (contactInfos?.length) {
      this.api
        .POST(
          Const.API_SERVICE_COMM_UNSUBSCRIBE + '/check-exist-by-contact-list',
          {
            contacts: this.contactInfos,
            scopes: ['carrier_bid'],
          }
        )
        .subscribe((res) => {
          if (res?.message === "Success") {
            this.contactInfos = res?.data?.list_data ?? [];
          }
        });
    }
  }

  isUnsubcribed(data) {
    if (!data) return false;
    const check = this.contactInfos.find(it => it.origin == data);
    return !!(check && check?.isUnsubscribed);
  }

  tooltipBaseRate(item): string {
    return `Lowest rate for the same lane & equipment. Based on the route ${item?.metadata?.baseRateInfo?.jobCode}`
  }

}
