import { Component, ViewChild, ElementRef } from "@angular/core";

import { BaseComponent } from "@abstract/BaseComponent";
import { DialogService } from "@dialogs/dialog.service";
import { Subscription } from "rxjs";
import { CarrierSaleDetailService } from "@app/admin/carrier-sale-detail/carrier-sale-detail.service";
import { Const } from "@const/Const";
import { DetailBidOutreachAnalytics } from "./analytics";
import dayjs from "dayjs";
import { DateUtil } from "@services/date-utils";
import { InputHelper } from "@services/input-helper";

@Component({
  selector: "carrier-sale-wave",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class CarrierSaleWave extends BaseComponent {
  bid: any;
  bidPlaceCount: number = 0;
  isbidPlaceCountLoading = false;
  isLoadWave: boolean = false;
  @ViewChild('waveListContainer') waveListContainer : ElementRef;
  private bidDetailSubscription: Subscription;
  wares = [
  ]
  waveListResponsive = [];
  waveListCurrentPage = 0;
  waveListCount = 0;
  totalPage = 0;
  sentBidSummary: {
    total_carrier?: number,
    total_sms?: number,
    total_email?: number,
    cost_sms?: string
  } = {}
  historyAddCarrier: Array<{ summary: string, datetime?: string }> = [];
  numOfCarrierMatched: number = 0;
  constructor(private carrierSaleDetailService: CarrierSaleDetailService) {
    super();
  }

  ngOnInit() {
    this.bidDetailSubscription = this.carrierSaleDetailService.bidDetail$.subscribe(bidDetail => {
      this.bid = bidDetail;
      this.getWaveList();
      this.getBidPlaceCount();
    });
  }

  ngOnDestroy() {
    if (this.bidDetailSubscription) {
      this.bidDetailSubscription.unsubscribe();
    }
  }

  private getBidPlaceCount() {
    this.isbidPlaceCountLoading = true;
    const url = `${Const.APIV2(Const.APIURI_CARRIER_BIDS)}/${this.bid?.id}/bid-place-count`;
    this.api.GET(url).subscribe((response) => {
      this.isbidPlaceCountLoading = false;
      this.bidPlaceCount = response.data?.count;
    }, err => {
      this.showErr(err);
      this.isbidPlaceCountLoading = false
    })
  }

  private getWaveList() {
    this.isLoadWave = true;
    const url = `${Const.APIV2(Const.APIURI_CARRIER_BIDS)}/${this.bid?.id}/wave-list`;
    this.api.GET(url).subscribe((response) => {
      this.isLoadWave = false;
      this.wares = this.processWaveItem(response?.data?.listOfDataWave ?? [])
      this.getWaveListResponsive(false);
      this.numOfCarrierMatched = response?.data?.numOfCarrierMatched ?? 0;
      this.sentBidSummary = this.formatSentBidSummary(response?.data?.sentBidSummary);
      this.historyAddCarrier = this.formatHistoryAddCarrier(response?.data?.historyAddCarrier ?? []);
    }, err => {
      this.showErr(err);
      this.isLoadWave = false;
    });
  }

  private formatSentBidSummary(sentBidSummary: any) {
    return {
      total_carrier: sentBidSummary?.total_carrier ?? 0,
      total_sms: sentBidSummary?.total_sms ?? 0,
      total_email: sentBidSummary?.total_email ?? 0,
      cost_sms: sentBidSummary?.cost_sms ? this.formatMoney(sentBidSummary.cost_sms) : '',
    };
  }

  private formatHistoryAddCarrier(historyAddCarrier: any[]) {
    const sourceMap = {
      'GEO_FEATURE_ASSIGNED': 'radius',
      'GEO_FEATURE_PLACED': 'radius',
      'GEO_FEATURE': 'radius',
      'DEDICATED_POOL': 'dedicated pool',
      'SAME_MARKET': 'market',
      'EXTERNAL_MATCHING': 'truck search',
      'COVERAGE_AREA': 'coverage area',
      'PREFERRED_LANE': 'preferred lane',
      'MANUAL': 'manual',
      'LOAD_BOARD_PUBLIC': 'public load board',
      'loadBoard': 'load board carrier portal'
    };

    return historyAddCarrier.map(item => {
      const numOfCarrier = item.numOfCarrier ?? 0;
      const carrierText = `${numOfCarrier} ${numOfCarrier > 1 ? 'carriers' : 'carrier'}`;
      const sourceText = sourceMap[item.source] ? `Source: ${sourceMap[item.source]}` : `Source: ${item.source ?? 'N/A'}`;
      const createdByText = item.source === 'MANUAL' || item.source === 'loadBoard' ? ` - ${item.createdBy ?? 'N/A'}` : '';
      const txt = `${item.source === 'MANUAL' || item.source === 'loadBoard' ? 'Manual Added' : 'Auto-Added'} ${carrierText} - ${sourceText}${createdByText}`;
      
      const obj: any = { summary: txt };
      const createdWhen = item.createdWhen ? DateUtil.dateToString(item.createdWhen, Const.FORMAT_GUI_DATETIME_V4) : '';
      if (createdWhen) {
        obj.datetime = createdWhen;
      }
      return obj;
    });
  }

  private processWaveItem(list) {
    let ret = [];
    for (const item of list) {
      const carrierCount = item.total_carrier || 0;
      const sendSmsCount = item.total_sms || 0;
      const sendEmailCount = item.total_email || 0;
      const totalSent = item.total_sent || 0;
      const timeString = item._id?.replace('BID_QUEUE:', '');
      const when = item?.when?.[0];
      let time = 'manual'
      if (when) {
        time = DateUtil.dateToString(when, Const.FORMAT_GUI_DATETIME_SHORT);
      } else if (timeString) {
        const date = dayjs.utc(timeString, "YYYYMMDDHHmmss").local();
        time = date.format("M/D/YY, h:mm A");
      }
      ret.push({
        time,
        isActive: !!(carrierCount > 0 && totalSent == (sendEmailCount + sendSmsCount)),
        carrierCount,
        sendEmailCount,
        sendSmsCount,
        createdBy: item.manualQueueBy ? item.manualQueueBy : 'system',
      });
    }
    return ret;
  }


  onTabSelectedIndexChange() {
  }

  onViewAnalytics() {
    DialogService.openDialog(DetailBidOutreachAnalytics, {
      nzTitle: 'Analytics',
      nzMaskClosable: false,
      nzComponentParams: {
        carrierBidId: this.bid?.id,
      },
      nzClassName: "modal-xl",
    })
  }

  getWaveListResponsive(isChangePage = true) {
    const waveWidth = 220;
    const waveListWidth = this.waveListContainer?.nativeElement?.offsetWidth;

    if (!waveListWidth) return;
    const count = Math.floor((waveListWidth - 20) / waveWidth);

    if (this.waveListCount == count && !isChangePage) return;
    this.waveListCount = count;
    this.totalPage = Math.ceil(this.wares.length / count);
    if (this.totalPage == 1) {
      this.waveListResponsive = this.wares;
      return;
    }
    this.waveListResponsive = [];
    for (let i = 0; i < count; i++) {
      if(this.wares[i + this.waveListCurrentPage * count]) this.waveListResponsive.push(this.wares[i + this.waveListCurrentPage * count]);
    }
  }

  onBtnNext() {
    if (this.waveListCurrentPage == this.totalPage-1) return;
    this.waveListCurrentPage++;
    this.getWaveListResponsive();
  }

  onBtnPrev() {
    if (this.waveListCurrentPage == 0) return;
    this.waveListCurrentPage--;
    this.getWaveListResponsive();
  }

  get isShowBtnNext() {
    return this.waveListCurrentPage != this.totalPage-1  && this.totalPage > 1;
  }

  get isShowBtnPrev() {
    return this.waveListCurrentPage != 0  && this.totalPage > 1;
  }
  formatMoney(amt: number | string) {
    return InputHelper.formatMoney2(`${amt || 0}`);
  }
}